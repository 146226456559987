import { Autoplay, Navigation } from 'swiper'
import { isMobileOnly } from 'react-device-detect'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IMG_PLACEHOLDER } from '@components/utils/textVariables'

import 'swiper/css'
import 'swiper/css/navigation'
import IntersectionObserverComponent from '@components/utils/IntersectionObserverComponent'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { getCurrentPage, getImageNameFromURL } from '@framework/utils/app-util'
import { EmptyGuid } from '@components/utils/constants'
import { useUI } from '@components/ui'
import { recordGA4Event } from '@components/services/analytics/ga4'

interface IBanner {
  current_page: string
  position: number
  banner_name: string
  component_type: string
  component_name: string
  url: string
  loggedin_status: boolean
  device: string
}

const OfferBanners = ({ data }: any) => {
  const css = { maxWidth: '100%', height: 'auto' }

  const { user } = useUI()

  const handleBannerClickGAEvent = (banner: IBanner) => {
    recordGA4Event(window, GA_EVENT.BANNER_CLICK, banner)
  }

  return (
    <>
      {data && data?.offerbanners?.length > 0 && (
        <>
          <div className="flex justify-between container sm:px-0 md:px-4 mx-auto lg:px-6 w-full mb-8">
            <Swiper
              slidesPerView={1.5}
              spaceBetween={8}
              navigation={false}
              loop={false}
              freeMode={true}
              modules={[Autoplay, Navigation]}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              slidesOffsetBefore={16}
              breakpoints={{
                640: {
                  slidesPerView: 1.5,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
                1520: {
                  slidesPerView: 3,
                  navigation: true,
                },
              }}
            >
              {data?.offerbanners?.map((offers: any, oid: number) => {
                const mobileImage = offers?.offerbanners_mobileheroimage
                const webImage = offers?.offerbanners_heroimage

                const bannersData = {
                  current_page: getCurrentPage() || '',
                  position: oid,
                  banner_name: isMobileOnly
                    ? mobileImage !== ''
                      ? getImageNameFromURL(mobileImage)
                      : getImageNameFromURL(webImage)
                    : getImageNameFromURL(webImage),
                  component_type: 'image',
                  component_name: 'offerbanners',
                  loggedin_status: !!(
                    user?.userId && user?.userId !== EmptyGuid
                  ),
                  url: isMobileOnly
                    ? mobileImage !== ''
                      ? mobileImage
                      : webImage
                    : webImage,
                  device: isMobileOnly ? 'Mobile' : 'Web',
                }
                return (
                  <div
                    key={oid}
                    onClick={() => handleBannerClickGAEvent(bannersData)}
                  >
                    <SwiperSlide
                      className="flex flex-col px-0"
                      key={`${oid}-f-p-${offers?.offerbanners_title}`}
                    >
                      <IntersectionObserverComponent
                        title="Home Page Offer Banner"
                        eventName={GA_EVENT.BANNER_IMPRESSIONS}
                        bannerData={bannersData}
                      >
                        <div className="relative content-center w-full bg-gray-dark">
                          <div className="content-center justify-center h-full text-center image-container item-center">
                            {isMobileOnly ? (
                              <>
                                {offers?.offerbanners_mobileheroimage != '' ? (
                                  <img
                                    src={
                                      offers?.offerbanners_mobileheroimage ||
                                      IMG_PLACEHOLDER
                                    }
                                    alt={offers?.offerbanners_title}
                                    style={css}
                                    width={100}
                                    height={50}
                                    className="image"
                                  />
                                ) : (
                                  <img
                                    src={
                                      offers?.offerbanners_heroimage ||
                                      IMG_PLACEHOLDER
                                    }
                                    alt={offers?.offerbanners_title}
                                    style={css}
                                    width={100}
                                    height={50}
                                    className="image"
                                  />
                                )}
                              </>
                            ) : (
                              <img
                                src={
                                  offers?.offerbanners_heroimage ||
                                  offers?.offerbanners_mobileheroimage ||
                                  IMG_PLACEHOLDER
                                }
                                alt={offers?.offerbanners_title}
                                style={css}
                                width={100}
                                height={50}
                                className="image"
                              />
                            )}
                          </div>
                        </div>
                      </IntersectionObserverComponent>
                    </SwiperSlide>
                  </div>
                )
              })}
            </Swiper>
          </div>
        </>
      )}
    </>
  )
}

export default OfferBanners
