// Base Imports
import React from 'react'
import dynamic from 'next/dynamic'

// Other Imports
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import { getComponents } from './helper'
import { IDeviceInfo } from '@components/ui/context'
import { CURRENT_HOME_COMPONENT_PAGE } from '@components/utils/constants'

export const TopCategory = dynamic(
  () => import('@components/home/CategoryRoundWidget'),
  { ssr: false }
)
export const HeroBanner = dynamic(() => import('@components/ui/Hero'), {
  ssr: false,
})
export const FavouritePayments = dynamic(
  () => import('@components/home/FavouritePayments'),
  { ssr: false }
)
export const FavouritePaymentsData = dynamic(
  () => import('@components/home/FavouritePaymentsData'),
  { ssr: false }
)
export const OfferBanners = dynamic(
  () => import('@components/home/OfferBanners'),
  { ssr: false }
)
export const BestSellerProducts = dynamic(
  () => import('@components/home/BannerBackground'),
  { ssr: false }
)
export const BestSellerTitle = dynamic(
  () => import('@components/home/BestSellerTitle'),
  { ssr: false }
)
export const CategoryList = dynamic(
  () => import('@components/home/BannerCategory'),
  { ssr: false }
)
export const CategoryListData = dynamic(
  () => import('@components/home/BannerCategoryData'),
  { ssr: false }
)
export const ColumnImage = dynamic(
  () => import('@components/home/ColumnImage'),
  { ssr: false }
)
export const ColumnVideo = dynamic(() => import('@components/home/ColumnVideo'))
export const RichTextContent = dynamic(
  () => import('@components/home/RichTextContent'),
  { ssr: false }
)
export const Loader = dynamic(() => import('@components/ui/LoadingDots'), {
  ssr: false,
})
export const MidBannerTop = dynamic(
  () => import('@components/home/CategoryColumnPanel'),
  { ssr: false }
)
export const MidBannerTopData = dynamic(
  () => import('@components/home/CategoryColumnPanelData'),
  { ssr: false }
)
export const TypeForm = dynamic(
  () => import('@components/home/TypeForm'),
  { ssr: false }
)
export const getMidBannerTop = (
  index: number,
  pageContents: any,
  deviceInfo: IDeviceInfo
) => {
  if (index === 1) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={1}
        mobileRowCount={1}
        deviceInfo={deviceInfo}
      />
    )
  } else if (index === 2) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={2}
        mobileRowCount={2}
        deviceInfo={deviceInfo}
      />
    )
  } else if (index === 3) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={3}
        mobileRowCount={3}
        deviceInfo={deviceInfo}
      />
    )
  } else if (index === 4) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={4}
        mobileRowCount={4}
        deviceInfo={deviceInfo}
      />
    )
  } else if (index === 6) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={6}
        mobileRowCount={3}
        deviceInfo={deviceInfo}
      />
    )
  } else if (index === 8) {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={8}
        mobileRowCount={4}
        deviceInfo={deviceInfo}
      />
    )
  } else {
    return (
      <MidBannerTop
        data={pageContents}
        columnCount={1}
        mobileRowCount={1}
        deviceInfo={deviceInfo}
      />
    )
  }
}

export const getMidBannerTopData = (
  index: number,
  pageContents: any,
  deviceInfo: IDeviceInfo,
  showPadding: boolean
) => {
  if (index === 1) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={1}
        mobileRowCount={1}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else if (index === 2) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={2}
        mobileRowCount={2}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else if (index === 3) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={3}
        mobileRowCount={3}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else if (index === 4) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={4}
        mobileRowCount={4}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else if (index === 6) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={6}
        mobileRowCount={3}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else if (index === 8) {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={8}
        mobileRowCount={4}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  } else {
    return (
      <MidBannerTopData
        data={pageContents}
        columnCount={1}
        mobileRowCount={1}
        deviceInfo={deviceInfo}
        showPadding={showPadding}
      />
    )
  }
}

function HomePageComponent({ pageContents, deviceInfo, hostName }: any) {
  const router = useRouter()

  const showPadding = pageContents?.hidepadding
    ? pageContents?.hidepadding?.toLowerCase() === 'true'
      ? false
      : true
    : true

  const generateComponents = (key: any) => {
    return getComponents(
      key,
      pageContents,
      deviceInfo,
      showPadding,
      CURRENT_HOME_COMPONENT_PAGE.OTHERS
    )
  }

  return !pageContents ? (
    <>
      <div className="flex w-full text-center flex-con">
        <Loader />
      </div>
    </>
  ) : (
    <>
      {(pageContents?.metatitle ||
        pageContents?.metadescription ||
        pageContents?.metakeywords) && (
          <>
            <NextHead>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
              />
              <link
                rel="canonical"
                id="canonical"
                href={
                  pageContents?.canonical ||
                  'https://www.damensch.com' + router.asPath
                }
              />
              {pageContents?.metatitle && (
                <>
                  <title>{pageContents?.metatitle}</title>
                  <meta name="title" content={pageContents?.metatitle} />
                </>
              )}

              {pageContents?.metadescription && (
                <meta
                  name="description"
                  content={pageContents?.metadescription}
                />
              )}

              {pageContents?.metakeywords && (
                <meta name="keywords" content={pageContents?.metakeywords} />
              )}
              <meta property="og:image" content={pageContents?.image} />
              {pageContents?.metatitle && (
                <meta
                  property="og:title"
                  content={pageContents?.metatitle}
                  key="ogtitle"
                />
              )}
              {pageContents?.metadescription && (
                <meta
                  property="og:description"
                  content={pageContents?.metadescription}
                  key="ogdesc"
                />
              )}
            </NextHead>
          </>
        )}

      {hostName && <input className="inst" type="hidden" value={hostName} />}
      {Object?.keys(pageContents)?.map((key: any) => {
        return generateComponents(key)
      })}
    </>
  )
}

export default HomePageComponent
